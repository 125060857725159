import queryString from "query-string";

const getCredentials = () => {
  const { tenant_id, userpool_id, identity_pool_id, client_id, region } =
    queryString.parse(window.location.search);
  console.log("aws tenant_id",tenant_id)
  return { tenant_id, userpool_id, identity_pool_id, client_id, region };
};

const getLocalStorageData = (key) => {
  const data = localStorage.getItem(key);
  if (data) {
    return JSON.parse(data);
  } else {
    null;
  }
};

const setLocalStorage = async (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

const getRefreshTime = (exp, before) => {
  const expiresAt = new Date(exp * 1000);
  const now = new Date();
  const refreshTime = expiresAt - now - before * 60 * 1000; // Refresh tokens 5 minutes before they expire
  return refreshTime;
};

const filterPagesByRole = (pages, rules) => {
  const visibleRules = rules.filter((rule) => rule.visible);

  return pages
    .map((page) => {
      const rule = visibleRules.find((rule) => rule.id === page.id);

      if (rule) {
        const editable = rule.editable;
        const isNav = rule?.isNav;
        const visibleChild =
          rule.child && rule.child.filter((rule) => rule.visible);
        const childRule =
          visibleChild?.length &&
          pages.filter((p) =>
            rule.child.some((child) => p.id === child.id && child.visible)
          );

        return {
          ...page,
          editable,
          isNav,
          child: childRule || false,
        };
      } else {
        return false
      }
    })
    .filter((page) => page.editable !== undefined);
};

function convertUTCDateToLocalDate(val) {
  let dateLocal = new Date(val);
  let timestamp = new Date(
    dateLocal.getTime() - dateLocal.getTimezoneOffset()
  );
  let result = timestamp.toLocaleString();
  return result;
}

const checkWhiteSpace =(t)=>{
  let hasWhitespace = /\s/.test(t);
  return hasWhitespace;
}

function removeCsvExtension(filename) {
  return filename.replace(/\.csv$/, '');
}

function extractKeyFromUrl(url) {
  // Create a URL object
  const urlObj = new URL(url);

  // Extract the pathname from the URL
  const pathname = urlObj.pathname;

  // The key is the part after the last slash in the pathname
  const key = pathname.substring(pathname.lastIndexOf("/") + 1);

  return decodeURIComponent(key); // decodeURIComponent will handle URL encoded characters like %24, %40, etc.
}


export {
  getCredentials,
  getLocalStorageData,
  setLocalStorage,
  getRefreshTime,
  filterPagesByRole,
  convertUTCDateToLocalDate,
  checkWhiteSpace,
  removeCsvExtension,
  extractKeyFromUrl
};
