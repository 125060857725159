import React from "react";

const Monitor = () => {
  return (
    <div>
      <h5>VPC Monitoring</h5>
      <iframe
        src="http://localhost:3002/d-solo/f78def46-9d77-4215-a7c8-d037fd2cb076/saas-fota-tenant?orgId=1&from=1685083599707&to=1685105199707&refresh=5s&theme=light&panelId=5"
        width="100%"
        height="100"
        frameBorder="0"
      ></iframe>
      <iframe
        src="http://localhost:3002/d-solo/f78def46-9d77-4215-a7c8-d037fd2cb076/saas-fota-tenant?orgId=1&from=1685083599707&to=1685105199707&refresh=5s&theme=light&panelId=5"
        width="100%"
        height="100"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default Monitor;
