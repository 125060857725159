import {
  Button
} from "@mui/material";
import AWS from "aws-sdk";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Container from "../../components/organizer/container/container";
import FormContainer from "../../components/organizer/form-container/form-container";
import { primary } from "../../constants/color";
import { ModeIcon } from "../../constants/icons";
import useAwsApiRequest from "../../hook/useAwsApiRequest";
import useStatusStore from "../../services/zustand/status-popup";
import { checkWhiteSpace } from "../../utilities/helper";
import { FormatNumber } from "../../utilities/numberFormat";
import VinTable from "./vin-table/vin-table";

const CreateCampaign = ({ goBack }) => {
  let tenantId = JSON.parse(localStorage.getItem("TenantID"));
  const cred = useSelector((state) => state.cred.cred);
  const tokens = useSelector((state) => state.tokens.tokens);
  const users = useSelector((state) => state.users.users);

  const [modelName, setModelName] = useState([]);
  const [vin, setVin] = useState([]);
  const [selectedName, setSelectedName] = useState("");
  const [firmwareVersion, setFirmwareVersion] = useState([]);
  const [selectedFirmwareVersion, setSelectedFirmwareVersion] = useState("");
  const [selectedVin, setSelectedVin] = useState([]);
  const [modal, openModal] = useState(false);
  const [firmwareFile, setFirmwareFile] = useState(null);
  const [firmwareFileName, setFirmwareFileName] = useState(null);
  const [formData, updateFormData] = useState({
    model_name: selectedName,
    version: selectedFirmwareVersion,
    tgu: "",
    releaseNotes: "",
    priority: "",
    description: "",
    firmwarePackage: "",
    tenant_id: tenantId || process.env.REACT_APP_USER_ROLE,
  });

  const [showVinList, setShowVinList] = useState(false);
  const [fileErrorMessage, setFileErrorMessage] = useState("");
  const [firmwarePackageFile, setFirmwarePackageFile] = useState(null);
  const [firmwarePackageFileName, setFirmwarePackageFileName] = useState(null);
  const [isFileValid, setIsFileValid] = useState(true);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isDateValid, setIsDateValid] = useState(false);
  const [formError, setFormError] = useState({
    dateRange: "",
    vinRange: "",
    modelName: "",
    firmwareVersion: "",
  });
  const [startRange, setStartRange] = useState("");
  const [endRange, setEndRange] = useState("");
  const [selectedVinList, setSelectedVinList] = useState([]);
  const [unSelectedVinList, setUnSelectedVinList] = useState([]);
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [vinCount, setVinCount] = useState(0);
  const [singleVinRange, setSingleVinRange] = useState("");
  const [vinIsGetting, setVinIsGetting] = useState(false);
  const [files, setFile] = useState(null);

  const vinListRef = useRef();

  const { statusCode, loading, data, error, apiRequest } = useAwsApiRequest();

  const {
    model_name,
    version,
    tgu,
    releaseNotes,
    firmwarePackage,
    priority,
    description,
  } = formData;

  const itemCount = selectedVin.length;

  const setMessage = useStatusStore((state) => state?.setMessage);

  const filteredVin = vin.filter((place) => {
    return [
      place.model_name === selectedName &&
        place.firmware_version === selectedFirmwareVersion,
    ];
  });

  const bucketName = `tenants3-${tenantId || process.env.REACT_APP_USER_ROLE}`;

  const s3 = new AWS.S3({
    region: tokens?.region,
    accessKeyId: cred?.accessKeyId,
    secretAccessKey: cred?.secretAccessKey,
    sessionToken: cred?.sessionToken,
  });

  const params = {
    Bucket: bucketName,
    Key: files?.name,
    Body: files,
    ContentType: files?.type,
  };
  const params1 = {
    Bucket: bucketName,
    Key: firmwareFileName,
    Body: firmwareFile,
  };

  const params2 = {
    Bucket: bucketName,
    Key: firmwarePackageFileName,
    Body: firmwarePackageFile,
  };

  useEffect(() => {
    getVehicleModel();
    getVehicle();
  }, []);

  useEffect(() => {
    if (selectedName) {
      setVinIsGetting(true);
      let data = {
        pathParam: {},
        endpoint: `http://localhost:8001/vehicleservice/firmwareversion/${selectedName}`,
        method: "GET",
        body: {},
      };

      const getVersion = async () => {
        const response = await apiRequest(data);

        if (response?.firmwareinfo && response?.firmwareinfo?.length) {
          const filteredArray = response?.firmwareinfo.reduce(
            (acc, current) => {
              const version = current?.firmware_version;
              if (
                version !== null &&
                version !== "null" &&
                !acc.some((item) => item?.firmware_version === version)
              ) {
                acc.push(current);
              }
              return acc;
            },
            []
          );
          setFirmwareVersion(filteredArray);
        }
        setVinIsGetting(false);
      };
      getVersion();
    }
  }, [selectedName]);

  useEffect(() => {
    setIsDateValid(false);

    if (!selectedName && tgu == "option3") {
      setFormError((prevState) => ({
        ...prevState,
        modelName: true,
      }));
      setMessage(null, "Select Model Name", "warning");
      return;
    }

    if (!selectedFirmwareVersion && tgu == "option3") {
      setFormError((prevState) => ({
        ...prevState,
        firmwareVersion: true,
      }));
      setMessage(null, "Select Firmware Version", "warning");
      return;
    }

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);
    const minStartDate = new Date(endDateObj);
    minStartDate.setFullYear(minStartDate.getFullYear() - 20);

    if (!startDate) {
      setFormError((prevState) => ({
        ...prevState,
        dateRange: "Select Start Date",
      }));
      return;
    }

    if (!endDate) {
      setFormError((prevState) => ({
        ...prevState,
        dateRange: "Select End Date",
      }));
      return;
    }

    if (startDateObj < minStartDate) {
      setFormError((prevState) => ({
        ...prevState,
        dateRange:
          "The start date must not be more than 20 years before the end date.",
      }));

      return;
    }

    if (endDateObj > new Date()) {
      setFormError((prevState) => ({
        ...prevState,
        dateRange: "The end date must not be in the future.",
      }));
      return;
    }

    if (startDate && endDate && startDateObj >= endDateObj) {
      setFormError((prevState) => ({
        ...prevState,
        dateRange: "The start date must be earlier than the end date.",
      }));
      return;
    }

    setFormError((prevState) => ({
      ...prevState,
      dateRange: "",
    }));
    setIsDateValid(true);
  }, [startDate, endDate, selectedName, selectedFirmwareVersion]);

  useEffect(() => {
    setIsDateValid(false);
    setFormError({
      dateRange: "",
      vinRange: "",
      modelName: "",
      firmwareVersion: "",
    });

    if (!selectedName && tgu == "option2") {
      setFormError((prevState) => ({
        ...prevState,
        modelName: true,
      }));
      setMessage(null, "Select Model Name", "warning");
      return;
    }

    if (!selectedFirmwareVersion && tgu == "option2") {
      setFormError((prevState) => ({
        ...prevState,
        firmwareVersion: true,
      }));
      setMessage(null, "Select Firmware Version", "warning");
      return;
    }
    console.log("range", startRange, endRange);
    if (!startRange || !endRange) {
      return;
    }

    const startRangeLast5 = startRange.slice(-5);
    const endRangeLast5 = endRange.slice(-5);

    console.log("startRangeLast5", startRangeLast5);
    if (!/^\d{5}$/.test(startRangeLast5)) {
      setFormError((prevState) => ({
        ...prevState,
        vinRange: "The last 5 digits of the Start Range must be numeric.",
      }));
      return;
    }
    console.log("endRangeLast5", endRangeLast5);
    if (!/^\d{5}$/.test(endRangeLast5)) {
      setFormError((prevState) => ({
        ...prevState,
        vinRange: "The last 5 digits of the End Range must be numeric.",
      }));
      return;
    }

    if (parseInt(startRangeLast5) >= parseInt(endRangeLast5)) {
      setFormError((prevState) => ({
        ...prevState,
        vinRange:
          "The start range last 5 digits must be less than the end range last 5 digits.",
      }));
      return;
    }

    if (startRange.length !== 17) {
      setFormError((prevState) => ({
        ...prevState,
        vinRange: "The start range must be 17 characters long.",
      }));
      return;
    }

    if (endRange.length !== 17) {
      setFormError((prevState) => ({
        ...prevState,
        vinRange: "The end range must be 17 characters long.",
      }));
      return;
    }

    // Clear errors if all validations pass
    setFormError((prevState) => ({
      ...prevState,
      vinRange: "",
      dateRange: "",
    }));
    setIsDateValid(true);
  }, [startRange, endRange, selectedName, selectedFirmwareVersion]);

  useEffect(() => {
    if (tgu !== "option3") {
      setEndDate("");
      setStartDate("");
    }

    if (tgu !== "option2") {
      setEndRange("");
      setStartRange("");
    }

    if (tgu !== "option1") {
      setSingleVinRange("");
    }
  }, [tgu]);

  useEffect(() => {
    setIsDateValid(false);
    if (!singleVinRange) {
      return;
    }
    const startRangeLast5 = singleVinRange.slice(-5);

    if (singleVinRange.length !== 17 && tgu == "option1") {
      setFormError((prevState) => ({
        ...prevState,
        vinRange: "The Vin range must be 17 characters long.",
      }));
      return;
    }

    if (!/^\d{5}$/.test(startRangeLast5)) {
      setFormError((prevState) => ({
        ...prevState,
        vinRange: "The last 5 digits of the VIN must be numeric.",
      }));
      return;
    }
    setVinCount(singleVinRange);
    setFormError((prevState) => ({
      ...prevState,
      vinRange: "",
      dateRange: "",
    }));
    setIsDateValid(true);
  }, [singleVinRange, selectedName, selectedFirmwareVersion]);

  const getVehicleModel = async () => {
    let data = {
      pathParam: {},
      endpoint: "http://localhost:8001/vehicleservice/vehiclemodel",
      method: "GET",
      body: {},
    };
    const response = await apiRequest(data);

    if (response?.vehicle_models?.length) {
      setModelName(response?.vehicle_models);
    }
  };

  const getVehicle = async () => {
    let data = {
      pathParam: {},
      endpoint: "http://localhost:8001/vehicleservice/vehicle",
      method: "GET",
      body: {},
    };
    const response = await apiRequest(data);
    if (response?.vehicle?.length) {
      setVin(response?.vehicle);
    }
  };

  const ValidationMessage = ({ message, required }) => {
    return (
      <div style={{ height: 3 }}>
        {required && <p className="error-message">{message}</p>}
      </div>
    );
  };

  const handleVersionChange = (e) => {
    console.log("e.target.id", e.target.id);

    setSelectedFirmwareVersion(e.target.value);

    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });

    clearTguHistory();
  };
  console.log("version", formData);

  const onHandleChange = (e) => {
    if (e.target.id == "tgu") {
      if (!selectedName) {
        setMessage(null, "Select Model Name", "warning");
        return;
      }

      if (!selectedFirmwareVersion) {
        setMessage(null, "Select Firmware Version", "warning");
        return;
      }

      removeSelectedDetails();
      setSingleVinRange("");
      setShowVinList(false);
      setIsDateValid(false);
    }

    updateFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleOpen = () => {
    openModal(true);
  };

  const handleClose = () => {
    openModal(false);
  };

  const handlePlaceChange = (e) => {
    const vin = e.target.value;
    const isChecked = e.target.checked;

    if (vin === "all") {
      setSelectedVin(
        isChecked
          ? filteredVin.map((p) => ({ vin: p.vin, thing_name: p.thing_name }))
          : []
      );
    } else {
      setSelectedVin((prevState) => {
        if (isChecked) {
          const item = filteredVin.find((p) => p.vin === vin);
          return [...prevState, { vin: item.vin, thing_name: item.thing_name }];
        } else {
          return prevState.filter((p) => p.vin !== vin);
        }
      });
    }
  };

  const handleReleaseNotesFileUpload = async (event) => {
    updateFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
    const file = event.target.files[0];
    setFirmwareFile(file);
    setFirmwareFileName(file.name);
    console.log(file);
    console.log(file.name);
  };

  const handleFirmwareFileUpload = async (event) => {
    const file = event.target.files[0];
    console.log("file",file)

    if (file && file.name.includes(" ")) {
      setIsFileValid(false);
      setFileErrorMessage("File name cannot contain white spaces");
    } else {
      setIsFileValid(true);
      setFileErrorMessage("");
    }
    updateFormData({
      ...formData,
      [event.target.id]: event.target.value,
    });
    setFirmwarePackageFile(file);
    setFirmwarePackageFileName(file.name);
    setFile(event.target.files[0]);

  };

  function removeSpecificKeys(request, keysToRemove) {
    const updatedRequest = { ...request };

    // Iterate through each key to potentially remove
    for (const key of keysToRemove) {
      if (
        updatedRequest[key] === "" ||
        updatedRequest[key] === null ||
        updatedRequest[key] === undefined ||
        updatedRequest[key] === false ||
        updatedRequest[key]?.length == 0
      ) {
        delete updatedRequest[key];
      }
    }

    return updatedRequest;
  }

  const vehicleOnboardRequest = {
    tenant_id: tenantId || process.env.REACT_APP_USER_ROLE,
    version: '1.2',
    priority: `${priority}`,
    description: `${description}`,
    modelName: selectedName,
    firmwareVersion: selectedFirmwareVersion,
    filename: firmwarePackageFileName,
    selectAll: isSelectedAll,
    startDate: startDate,
    endDate: endDate,
    unselectedVins: unSelectedVinList,
    startVin: startRange,
    endVin: endRange,
    selectedVins: selectedVinList,
    numChars: startRange ? "5" : "",
    created_by: users
  };

 

  const handleSubmit = async () => {
    const keysToRemove = [
      "startDate",
      "endDate",
      "startVin",
      "endVin",
      "selectAll",
      "selectedVins",
      "unselectedVins",
      "numChars",
    ];

    // Remove specific keys based on their values
    const updatedRequest = removeSpecificKeys(
      vehicleOnboardRequest,
      keysToRemove
    );
    console.log("params",params)
    const res = await s3.upload(params).promise();
   
    const s3URL = res?.Location;
    console.log("s3URL", s3URL);
    let data = {
      pathParam: {},
      endpoint: "http://localhost:3000/campaign/submitcampaign",
      method: "POST",
      userRole: "SuperAdmin",
      body: {
        ...updatedRequest,
        s3_url: s3URL,
      },
    };

    

    const response = await apiRequest(data);
    if (response?.status == 200) {
      setTimeout(() => {
        goBack(1);
      }, 1000);
    } else {
    }
  };

  const getVinList = (type) => {
    if (tgu == "option1") {
      setIsDateValid(false);

      if (!selectedName) {
        setFormError((prevState) => ({
          ...prevState,
          modelName: true,
        }));
        return;
      }

      if (!selectedFirmwareVersion) {
        setFormError((prevState) => ({
          ...prevState,
          firmwareVersion: true,
        }));
        return;
      }
      setIsDateValid(true);
    }

    setShowVinList(true);
    setTimeout(() => {
      vinListRef?.current?.loadData();
    }, 500);
  };

  const updateDateRange = (t, d) => {
    if (t == "s") {
      setStartDate(d);
    } else {
      setEndDate(d);
    }
  };

  const updateVinRange = (t, r) => {
    if (checkWhiteSpace(r)) {
      return;
    }

    if (t == "s") {
      setStartRange(r);
    } else {
      setEndRange(r);
    }
  };

  const addVinDetails = (
    isSelectedAll,
    unSelectedList,
    selectedList,
    count
  ) => {
    console.log("unSelectedList", unSelectedList);
    if (unSelectedList?.length) {
      let unList = unSelectedList.map((x) => x.vin);
      setUnSelectedVinList(unList);
    }

    if (selectedList?.length) {
      let seList = selectedList.map((x) => x.vin);
      setSelectedVinList(seList);
    }
    setIsSelectedAll(isSelectedAll);
    setShowVinList(false);
    setVinCount(count);
  };

  const removeSelectedDetails = () => {
    setIsSelectedAll(false);
    setUnSelectedVinList([]);
    setSelectedVinList([]);
  };

  const updateSingleVinRange = (r) => {
    if (checkWhiteSpace(r)) {
      return;
    }
    setSelectedVinList([]);
    setSingleVinRange(r);
  };

  const addVinRange = () => {
    setSelectedVinList([singleVinRange]);
  };

  const updateModelName = (v) => {
    setSelectedName(v);
    clearTguHistory();
  };

  const clearTguHistory = () => {
    updateFormData({
      ...formData,
      ["tgu"]: "",
    });
    setShowVinList(false);
    setSelectedVinList([]);
    setStartDate("");
    setEndDate("");
    setStartRange("");
    setEndRange("");
    setSingleVinRange("");
  };

  const clickModelName = () => {
    if (modelName.length == 0) {
      getVehicleModel();
      getVehicle();
    }
  };

  const clickFirmwareVersion = () => {
    if (!selectedName) {
      setMessage(null, "Model Name Is Not Selected", "warning");
    }

    if (firmwareVersion?.length == 0) {
      setMessage(
        null,
        "Firmware Version Not Loaded. Please Select Model Name Again",
        "warning"
      );
    }
  };

  return (
    <Container>
      <div
        style={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
          // alignItems: "center",
          justifyContent: "space-evenly",
        }}
      >
        <FormContainer
          minusWidth={0}
          header={true}
          title={"Create Campaign"}
          button={true}
          handleSubmit={handleSubmit}
        >
          <div
            className="input-row"
            style={{ height: 80, marginTop: 10, paddingBottom: 1 }}
          >
            <div
              className="input-container"
              style={{ width: "100%", paddingBottom: 2 }}
            >
              <p className="input-title">Model Name *</p>
              <select
                className="input"
                id="model_name"
                value={selectedName}
                onChange={(e) => updateModelName(e.target.value)}
                required
                onClick={clickModelName}
              >
                <option value="">Select Model Name</option>
                {modelName.map((model, index) => (
                  <option key={index} value={model.model_name}>
                    {model.model_name}
                  </option>
                ))}
              </select>
              <ValidationMessage
                message={"Model Name Is Required*"}
                required={formError.modelName}
              />
            </div>
            <div
              className="input-container"
              style={{ width: "100%", paddingBottom: 2 }}
            >
              <p className="input-title">Firmware Version *</p>
              <select
                className="input"
                id="version"
                value={selectedFirmwareVersion}
                required
                onChange={handleVersionChange}
                onClick={clickFirmwareVersion}
              >
                <option value={""}>
                  {vinIsGetting
                    ? "Getting Firmware Version ..."
                    : "Select Firmware Version"}
                </option>

                {firmwareVersion &&
                  firmwareVersion.map((version) => (
                    <option key={version.id} value={version.firmware_version}>
                      {FormatNumber(version.firmware_version)}
                    </option>
                  ))}
              </select>
              
              <ValidationMessage
                message={"Firmware Version Is Required*"}
                required={formError.firmwareVersion}
              />
            </div>
          </div>
          <div className="input-row" style={{ marginTop: 5 }}></div>
          <div className="input-row" style={{ height: 140, marginTop: 5 }}>
            <div className="input-container" style={{ width: "100%" }}>
              <p className="input-title">Filter By *</p>
              <select
                className="input"
                id="tgu"
                value={tgu}
                onChange={onHandleChange}
              >
                <option value="">--Select--</option>
                <option value="option1">VIN</option>
                <option value="option2">VIN Range</option>
                <option value="option3">Date Range</option>
              </select>
            </div>
            <div className="input-container" style={{ width: "100%" }}>
              <p className="input-title">Description *</p>
              <textarea
                className="input"
                placeholder="Enter Description"
                id="description"
                value={formData.description}
                as="textarea"
                maxLength={100}
                rows={3}
                required
                onChange={onHandleChange}
                style={{ height: 80 }}
              />
              <ValidationMessage
                message={"Description Is Required*"}
                // required={validationError.model_name}
              />
            </div>
            {/* <div className="input-container" style={{ width: "100%" }}>
              <p className="input-title">Release Note *</p>
              <input
                type="file"
                className="form-control"
                required
                id="tenant_logo_filename"
                accept=".jpeg,.png"
                style={{ fontSize: 12, fontWeight: 600, fontFamily: "Rubik" }}
                onChange={handleReleaseNotesFileUpload}
                value={releaseNotes}
              />
              <ValidationMessage
                message={"Release Note Is Required*"}
                // required={validationError.model_name}
              />
            </div> */}
          </div>

          {tgu === "option3" && (
            <>
              <div
                className="input-row"
                style={{
                  height: 100,
                  marginTop: 5,
                  paddingRight: 15,
                  marginTop: 15,
                  border: "2px solid #f2f2f2",
                  paddingBottom: 10,
                  boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
                  backgroundColor: "#fefbf1",
                }}
              >
                <div className="input-container" style={{ width: "40%" }}>
                  <p className="input-title">Select Start Date *</p>
                  <input
                    type="date"
                    className="input"
                    style={{
                      borderWidth: 0.5,
                      borderColor: "gray",
                      borderRadius: 5,
                      width: "100%",
                    }}
                    value={startDate}
                    onChange={(e) => updateDateRange("s", e.target.value)}
                  />
                </div>
                <div className="input-container" style={{ width: "40%" }}>
                  <p className="input-title">Select End Date *</p>
                  <input
                    type="date"
                    className="input"
                    style={{
                      borderWidth: 0.5,
                      borderColor: "gray",
                      borderRadius: 5,
                      width: "100%",
                    }}
                    value={endDate}
                    onChange={(e) => updateDateRange("e", e.target.value)}
                  />
                </div>
                <div style={{ marginTop: "6%" }}>
                  <Button
                    onClick={getVinList}
                    variant="contained"
                    sx={{
                      backgroundColor: primary,
                    }}
                    disabled={!isDateValid}
                  >
                    Get Vin
                  </Button>
                </div>
              </div>

              <ValidationMessage
                message={`${formError.dateRange} *`}
                required={formError.dateRange}
              />
            </>
          )}

          {tgu === "option2" && (
            <>
              <div
                className="input-row"
                style={{
                  height: 100,
                  marginTop: 5,
                  paddingRight: 15,
                  marginTop: 15,
                  border: "2px solid #f2f2f2",
                  paddingBottom: 10,
                  boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
                  backgroundColor: "#fefbf1",
                }}
              >
                <div className="input-container" style={{ width: "40%" }}>
                  <p className="input-title">Enter Start Range *</p>
                  <input
                    className="input"
                    style={{
                      borderWidth: 0.5,
                      borderColor: "gray",
                      borderRadius: 5,
                      width: "100%",
                    }}
                    value={startRange}
                    onChange={(e) => updateVinRange("s", e.target.value)}
                  />
                </div>
                <div className="input-container" style={{ width: "40%" }}>
                  <p className="input-title">Enter End Range *</p>
                  <input
                    className="input"
                    style={{
                      borderWidth: 0.5,
                      borderColor: "gray",
                      borderRadius: 5,
                      width: "100%",
                    }}
                    value={endRange}
                    onChange={(e) => updateVinRange("e", e.target.value)}
                  />
                </div>
                <div style={{ marginTop: "6%" }}>
                  <Button
                    onClick={getVinList}
                    variant="contained"
                    sx={{
                      backgroundColor: primary,
                    }}
                    disabled={!isDateValid}
                  >
                    Get Vin
                  </Button>
                </div>
              </div>
              <ValidationMessage
                message={`${formError.vinRange} *`}
                required={formError.vinRange}
              />
            </>
          )}

          {tgu === "option1" && (
            <>
              <div
                className="input-row"
                style={{
                  height: 100,
                  marginTop: 5,
                  paddingRight: 15,
                  marginTop: 15,
                  border: "2px solid #f2f2f2",
                  paddingBottom: 10,
                  boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
                  backgroundColor: "#fefbf1",
                }}
              >
                <div className="input-container" style={{ width: "40%" }}>
                  <p className="input-title">Enter Vin Range *</p>
                  <input
                    className="input"
                    style={{
                      borderWidth: 0.5,
                      borderColor: "gray",
                      borderRadius: 5,
                      width: "100%",
                    }}
                    value={singleVinRange}
                    onChange={(e) => updateSingleVinRange(e.target.value)}
                  />
                </div>

                <div style={{ marginTop: "6%" }}>
                  <Button
                    onClick={addVinRange}
                    variant="contained"
                    sx={{
                      backgroundColor: primary,
                    }}
                    disabled={!isDateValid}
                  >
                    Add
                  </Button>
                </div>
              </div>
              <ValidationMessage
                message={`${formError.vinRange} *`}
                required={formError.vinRange}
              />
            </>
          )}

          {selectedVinList?.length ||
          isSelectedAll ||
          unSelectedVinList?.length ? (
            <div
              style={{
                width: "100%",
                height: "50px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "15px",
                fontSize: "14px",
                fontFamily: "Rubik",
                boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.2)",
                backgroundColor: "#fefbf1",
                marginTop: "10px",
                cursor: "pointer",
              }}
            >
              Vin Selected
              <div
                style={{
                  padding: "5px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#19172C",
                  borderRadius: "50px",
                }}
                onClick={removeSelectedDetails}
              >
                <ModeIcon
                  style={{ fontSize: 14, fontWeight: 600, color: "white" }}
                />
              </div>
            </div>
          ) : null}

          {/* {selectedName && tgu && filteredVin.length > 0 && (
            <div className="input-row">
              <div className="input-container">
                <Button
                  onClick={handleOpen}
                  variant="contained"
                  sx={{ backgroundColor: "#4822B4" }}
                >
                  Select VIN
                </Button>
                <Dialog open={modal}>
                  <Grid container spacing={2}>
                    <DialogTitle
                      variant="h6"
                      sx={{
                        color: "#4822B4",
                        fontFamily: "cursive",
                        marginLeft: "3%",
                        marginTop: "3%",
                      }}
                    >
                      Select VIN
                      <input
                        style={{
                          maxWidth: "200px",
                          border: "2px solid #080808",
                          float: "right",
                          marginLeft: "200px",
                        }}
                        type="text"
                        className="form-control"
                        placeholder="Search vin name..."
                        value={value}
                        onChange={(e) => setValue(e.target.value)}
                      />
                    </DialogTitle>
                    <DialogContent
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        paddingLeft: "50px",
                      }}
                    >
                      <div style={{ fontWeight: "bold" }}>
                        {itemCount > 0
                          ? `You have selected ${itemCount} records`
                          : null}
                      </div>
                      <br />
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              color: "black",
                              "&.Mui-checked": {
                                color: "#4822B4",
                              },
                            }}
                            checked={selectedVin.length === filteredVin.length}
                            onChange={handlePlaceChange}
                            value="all"
                          />
                        }
                        label={
                          <Typography variant="inherit" fontWeight="bold">
                            Select All
                          </Typography>
                        }
                        sx={{ flexBasis: "100%", marginBottom: "1%" }}
                        display="block"
                      />
                      {filteredVin
                        .filter((vehicleModels) => {
                          if (value === "") {
                            return vehicleModels;
                          } else if (
                            vehicleModels.vin
                              .toLowerCase()
                              .includes(value.toLowerCase())
                          ) {
                            return vehicleModels;
                          }
                          return null;
                        })
                        .map((place) => (
                          <Grid place xs={6} md={4} key={place.id}>
                            <div key={place.id}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    sx={{
                                      color: "black",
                                      "&.Mui-checked": {
                                        color: "#4822B4",
                                      },
                                    }}
                                    value={place.vin}
                                    checked={selectedVin.some(
                                      (selectedPlace) =>
                                        selectedPlace.vin === place.vin &&
                                        selectedPlace.thing_name ===
                                          place.thing_name
                                    )}
                                    onChange={handlePlaceChange}
                                  />
                                }
                                label={place.vin}
                                style={{ flexBasis: "33.33%" }}
                              />
                            </div>
                          </Grid>
                        ))}
                    </DialogContent>
                    <DialogActions>
                      <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{
                          backgroundColor: "#4822B4",
                          marginLeft: "470px",
                        }}
                      >
                        Continue
                      </Button>
                    </DialogActions>
                  </Grid>
                </Dialog>
              </div>
            </div>
          )} */}

          <div className="input-row" style={{ height: 80, marginTop: 10 }}>
            <div className="input-container" style={{ width: "100%" }}>
              <p className="input-title">Priority *</p>
              <select
                className="input"
                id="priority"
                value={priority}
                required
                onChange={onHandleChange}
              >
                <option value="">--Select--</option>
                <option value="high">HIGH</option>
                <option value="medium">MEDIUM</option>
                <option value="low">LOW</option>
              </select>
              <ValidationMessage
                message={"Priority Is Required*"}
                // required={validationError.model_name}
              />
            </div>
            <div className="input-container" style={{ width: "100%" }}>
              <p className="input-title">Firmware Package *</p>
              <input
                type="file"
                className="form-control"
                required
                id="tenant_logo_filename"
                accept=".jpeg,.png"
                style={{ fontSize: 12, fontWeight: 600, fontFamily: "Rubik" }}
                onChange={handleFirmwareFileUpload}
                value={firmwareFileName}
              />
              <ValidationMessage
                message={"Firmware Package Is Required*"}
                // required={validationError.model_name}
              />
            </div>
          </div>

          {/* <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              height: 70,
            }}
          >
            <div
              style={{
                width: 120,
                height: 35,
                backgroundColor: secondary,
                borderRadius: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 14,
                fontWeight: 600,
                color: "white",
                fontFamily: "Rubik",
                cursor: "pointer",
              }}
              onClick={handleSubmit}
            >
              Submit
            </div>
          </div> */}
          <div style={{ height: "20px" }} />
        </FormContainer>
        {showVinList && (
          <FormContainer minusWidth={120} header={true} title={"Select Vin"}>
            <VinTable
              tgu={tgu}
              startDate={startDate}
              endDate={endDate}
              startRange={startRange}
              endRange={endRange}
              modelName={selectedName}
              firmwareVersion={selectedFirmwareVersion}
              addVinDetails={addVinDetails}
              ref={vinListRef}
            />
          </FormContainer>
        )}
      </div>
    </Container>
  );
};

export default CreateCampaign;
