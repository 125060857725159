import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import "./recordList.css";
import { useSpring, animated } from "@react-spring/web";
import Close from "@mui/icons-material/Close";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
  FaFileDownload,
} from "../../../constants/icons";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactPaginate from "react-paginate";
import useAxiosApiRequest from "../../../hook/useAxiosApiRequest";
import { CSVLink } from "react-csv";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";

const RecordList = ({ type, openRecordList, onClose, records }) => {
  const { loading, apiRequest } = useAwsApiRequest();
  const recordType = useRef();
  const [recordData, setRecordData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(1);

  const slideIn = useSpring({
    transform: openRecordList ? "translateY(0%)" : "translateY(100%)",
    opacity: openRecordList ? 1 : 0,
  });

  const width = useSelector((state) => state.width.width);
  const boxWidth = (window.innerWidth - width) / 5;
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (type) {
      loadData(page);
    }
  }, [type]);

  const loadData = async (p) => {
    let data = {
      pathParam: {},
      endpoint:
        "http://localhost:8001/vehicleservice/vehicle/validationdetails",
      method: "GET",
      body: {},
      queryParams: {
        "record-type": type,
        "file-name": records?.filename,
        page: p,
        limit: 10,
      },
      // defaultTen: "9e1be8c7-10cf-40b7-ae8e-3ada6df26cc0",
    };
    const response = await apiRequest(data);

    if (response) {
      setRecordData(response?.data);
      setPageCount(response?.pagination?.total / 10);
    }
  };

  const handlePageClick = (page) => {
    setPage(page.selected);
    loadData(page.selected + 1);
  };

  const downloadDemoFile = () => {
    const link = document.createElement("a");
    link.href = pdfFile;
    link.download = "Demo-vehicle-data.pdf"; // Specify the filename for download
    link.click();
  };

  return (
    <animated.div className="bottom-sheet" style={slideIn}>
      <div className="sheet-header">
        <h2 className="sheet-title">{capitalizeFirstLetter(type)} Records</h2>
        <div className="description-details">
          <div>File Name: {records?.filename}</div>
        </div>
        <div className="description-details">
          <div>Date: {moment(records?.date).format("MMM Do YYYY")}</div>
        </div>


        
        <CSVLink data={recordData}>
          <div
            style={{
              backgroundColor: "#19172C",
              height: 30,
              width: 30,
              borderRadius: 30,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              cursor: "pointer",
              marginRight: 10,
            }}
          >
            <FaFileDownload />
          </div>
        </CSVLink>

        <div
          style={{
            backgroundColor: "#19172C",
            height: 30,
            width: 30,
            borderRadius: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => {
            setPage(1);
            onClose();
          }}
        >
          <Close />
        </div>
      </div>
      <div className="sheet-content">
        <div
          className="single-container"
          style={{ backgroundColor: "#A6B8DC" }}
        >
          <div className="slBox" style={{ width: boxWidth / 3 }}>
            Sl.No
          </div>
          <div className="slBox" style={{ width: boxWidth }}>
            Vin
          </div>
          <div className="slBox" style={{ width: boxWidth }}>
            Model Name
          </div>
          <div className="slBox" style={{ width: boxWidth }}>
            Fleet Name
          </div>
          <div className="slBox" style={{ width: boxWidth }}>
            Version
          </div>
          <div className="slBox" style={{ width: boxWidth }}>
            Region
          </div>
        </div>
        <div className="sheet-record-list">
          {recordData?.length ? (
            recordData.map((item, index) => (
              <div
                className="single-container"
                style={{
                  backgroundColor: index % 2 == 0 ? "white" : "#f2f2f2",
                }}
                key={index}
              >
                <div
                  className="slBox"
                  style={{
                    width: boxWidth / 3,
                    fontSize: 11,
                    fontWeight: "500",
                  }}
                >
                  {index + 1}
                </div>
                <div
                  className="slBox"
                  style={{ width: boxWidth, fontSize: 12, fontWeight: "500" }}
                >
                  {item?.vin}
                </div>
                <div
                  className="slBox"
                  style={{ width: boxWidth, fontSize: 12, fontWeight: "500" }}
                >
                  {item?.model_name}
                </div>
                <div
                  className="slBox"
                  style={{ width: boxWidth, fontSize: 12, fontWeight: "500" }}
                >
                  {item.fleet_name}
                </div>
                <div
                  className="slBox"
                  style={{ width: boxWidth, fontSize: 12, fontWeight: "500" }}
                >
                  {item?.version}
                </div>
                <div
                  className="slBox"
                  style={{ width: boxWidth, fontSize: 12, fontWeight: "500" }}
                >
                  {item?.region}
                </div>
              </div>
            ))
          ) : (
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: 14,
                fontFamily: "Rubik",
              }}
            >
              No Data Found
            </div>
          )}
        </div>
      </div>
      <div className="pagination-box">
        <ReactPaginate
          previousLabel={
            <ArrowBackIosNewOutlinedIcon
              style={{ fontSize: 14, fontWeight: 600 }}
            />
          }
          nextLabel={
            <ArrowForwardIosOutlinedIcon
              style={{ fontSize: 14, fontWeight: 600 }}
            />
          }
          breakLabel={"...."}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttns"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </animated.div>
  );
};

export default RecordList;
