import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import indicator from "../../assets/lottie/loader.json";
import FormContainer from "../../components/organizer/form-container/form-container";
import useAwsApiRequest from "../../hook/useAwsApiRequest";

const NewOnboard = ({ selectedData, action, goBack }) => {
  let tenantId = JSON.parse(localStorage.getItem("TenantID"));

  const width = useSelector((state) => state.width.width);
  const userType = useSelector((state) => state.userType.userType);
  const [formData, setFormData] = useState({
    vin: "",
    model_name: "",
    fleet_name: "",
    region: "",
    version: "",
    tenant_id: tenantId || process.env.REACT_APP_USER_ROLE,
    // tenant_name: "",
  });
  const [validationError, setValidationError] = useState({
    vin: false,
    vinNumeric: false,
    vinLength:false,
    model_name: false,
    fleet_name: false,
    region: false,
    version: false,
    tenant_name: false,
  });
  const [modelNames, setModelName] = useState([]);
  const [fleetList, setFleetList] = useState([]);
  const [tenantName, setTenantName] = useState([]);

  const { statusCode, loading, data, error, apiRequest } = useAwsApiRequest();
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: indicator,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  useEffect(() => {
    if (error) {
      const msg = error?.response?.data?.msg;
      if (msg) {
        toast.warn(msg, {
          position: "top-right",
        });
      } else {
        toast.error("No Network Connection", {
          position: "top-right",
        });
      }
    }
  }, [error]);

  useEffect(() => {
    getVehicleModel();
    getFleet();
    // getTenant();
  }, []);

  const onHandleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setValidationError({ ...validationError, [id]: false });
  };

  const validateForm = () => {
    let isValid = true;

    if (!formData.vin) {
      setValidationError((prevState) => ({ 
        ...prevState, 
        vin: true, 
        vinLength: false, 
        vinNumeric: false 
      }));
      isValid = false;
    } 
    else if (formData.vin.length !== 17) {
      setValidationError((prevState) => ({ 
        ...prevState, 
        vin: false, 
        vinLength: true, 
        vinNumeric: false 
      }));
      isValid = false;
    } 
    else if (!/^\d{5}$/.test(formData.vin.slice(-5))) {
      setValidationError((prevState) => ({ 
        ...prevState, 
        vin: false, 
        vinLength: false, 
        vinNumeric: true 
      }));
      isValid = false;
    } 
    else {
      setValidationError((prevState) => ({ 
        ...prevState, 
        vin: false, 
        vinLength: false, 
        vinNumeric: false 
      }));
    }    

    if (!formData.fleet_name) {
      setValidationError((prevState) => ({ ...prevState, fleet_name: true }));
      isValid = false;
    } else {
      setValidationError((prevState) => ({ ...prevState, fleet_name: false }));
    }

    if (!formData.model_name) {
      setValidationError((prevState) => ({ ...prevState, model_name: true }));
      isValid = false;
    } else {
      setValidationError((prevState) => ({ ...prevState, model_name: false }));
    }

    if (!formData.region) {
      setValidationError((prevState) => ({
        ...prevState,
        region: true,
      }));
      isValid = false;
    } else {
      setValidationError((prevState) => ({
        ...prevState,
        region: false,
      }));
    }

    if (!formData.version) {
      setValidationError((prevState) => ({
        ...prevState,
        version: true,
      }));
      isValid = false;
    } else {
      setValidationError((prevState) => ({
        ...prevState,
        version: false,
      }));
    }

    return isValid;
  };

  const onHandleSubmit = async () => {
    if (validateForm()) {
      if (action !== "update") {
        console.log("formData", formData);
        let data = {
          pathParam: {},
          endpoint: `http://localhost:8080/vehicleservice/vehicle`,
          method: "POST",
          body: formData,
        };
        const response = await apiRequest(data);
        if (response?.status == 200) {
          setFormData({
            fleet_name: "",
            vin: "",
            version: "",
            region: "",
            model_name: "",
            tenant_id: tenantId || process.env.REACT_APP_USER_ROLE,
          });
          toast.success("Successfully Onboarded Vehicle", {
            position: "top-right",
          });
          setTimeout(() => {}, 5000);
        }
      } else {
        let data = {
          pathParam: {},
          endpoint: `http://localhost:8082/fleetservice/fleet/${selectedData?.fleet_id}`,
          method: "PUT",
          body: formData,
        };

        const response = await apiRequest(data);
        if (response?.status == 200) {
          setFormData({
            fleet_name: "",
            fleet_description: "",
            fleet_id: "",
            tenant_id: tenantId || process.env.REACT_APP_USER_ROLE,
          });
          toast.success("Successfully Onboarded Vehicle", {
            position: "top-right",
          });
          setTimeout(() => {
            goBack(1);
          }, 5000);
        }
      }
    }
  };

  const getVehicleModel = async () => {
    let data = {
      pathParam: {},
      endpoint: "http://localhost:8080/vehicleservice/vehiclemodel",
      method: "GET",
      body: {},
    };

    const response = await apiRequest(data);

    if (response?.vehicle_models.length) {
      setModelName(response.vehicle_models);
    }
  };

  const getFleet = async () => {
    let data = {
      pathParam: {},
      endpoint: `http://localhost:8082/fleetservice/fleet`,
      method: "GET",
      body: {},
    };

    const response = await apiRequest(data);

    if (response?.records?.length) {
      setFleetList(response.records);
    }
  };

  const getTenant = async () => {
    let data = {
      pathParam: {},
      endpoint: `http://localhost:8082/tenantservice/tenant`,
      method: "GET",
      body: {},
    };

    const response = await apiRequest(data);

    if (response?.data?.length) {
      setTenantName(response.data);
    }
  };

  const ValidationMessage = ({ message, required }) => {
    return (
      <div style={{ height: 3 }}>
        {required && <p className="error-message">{message}</p>}
      </div>
    );
  };
  return (
    <div
      style={{
        width: window.innerWidth - width - 30,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <FormContainer header={true} minusWidth={0} title={"Onboard Vehicle"}>
        <div className="input-row" style={{ height: 80, marginTop: 10 }}>
          <div className="input-container" style={{ width: "100%" }}>
            <p className="input-title">Enter Vin Number *</p>
            <input
              type="string"
              className="input"
              id="vin"
              value={formData.vin}
              placeholder="Enter Enter Vin Number"
              maxLength={17}
              required
              onChange={onHandleChange}
            />
             <ValidationMessage
              message={"The Vin number must be 17 characters long"}
              required={validationError.vinLength}
            />
             <ValidationMessage
              message={"The last 5 characters must be numeric"}
              required={validationError.vinNumeric}
            />
            <ValidationMessage
              message={"Vin Number Is Required*"}
              required={validationError.vin}
            />
          </div>
          {/* <div
            className="input-container"
            style={{
              width: "100%",
              display: userType == "superAdminRole" ? "flex" : "none",
            }}
          >
            <p className="input-title">Select Tenant *</p>

            <select
              className="input"
              id="tenant_name"
              value={formData.tenant_name}
              placeholder="Select Tenant"
              required
              onChange={onHandleChange}
            >
              <option
                style={{
                  fontSize: 12,
                  fontFamily: "Rubik",
                  fontWeight: 500,
                  padding: 5,
                  height: 50,
                }}
              >
                {"Select Tenant"}
              </option>
              {tenantName &&
                tenantName.map((t) => (
                  <option
                    key={t.id}
                    style={{
                      fontSize: 12,
                      fontFamily: "Rubik",
                      fontWeight: 500,
                      padding: 5,
                      height: 50,
                    }}
                  >
                    {t.tenantName}
                  </option>
                ))}
            </select>
            <ValidationMessage
              message={"Tenant Is Required*"}
              required={validationError.tenant_name}
            />
          </div> */}
        </div>

        <div className="input-row" style={{ height: 80, marginTop: 10 }}>
          <div className="input-container" style={{ width: "100%" }}>
            <p className="input-title">Select Model Name *</p>

            <select
              className="input"
              id="model_name"
              value={formData.model_name}
              placeholder="Select Model Name"
              required
              onChange={onHandleChange}
            >
              <option
                style={{
                  fontSize: 12,
                  fontFamily: "Rubik",
                  fontWeight: 500,
                  padding: 5,
                  height: 50,
                }}
              >
                {"Select Model Name"}
              </option>
              {modelNames &&
                modelNames.map((getModelName) => (
                  <option
                    key={getModelName.id}
                    style={{
                      fontSize: 12,
                      fontFamily: "Rubik",
                      fontWeight: 500,
                      padding: 5,
                      height: 50,
                    }}
                  >
                    {getModelName.model_name}
                  </option>
                ))}
            </select>
            <ValidationMessage
              message={"Model Name Is Required*"}
              required={validationError.model_name}
            />
          </div>
          <div className="input-container" style={{ width: "100%" }}>
            <p className="input-title">Fleet Name *</p>

            <select
              className="input"
              id="fleet_name"
              value={formData.fleet_name}
              placeholder="Select Model Name"
              required
              onChange={onHandleChange}
            >
              <option
                style={{
                  fontSize: 12,
                  fontFamily: "Rubik",
                  fontWeight: 500,
                  padding: 5,
                  height: 50,
                }}
              >
                {"Select Fleet List"}
              </option>
              {fleetList &&
                fleetList.map((fleet) => (
                  <option
                    key={fleet.id}
                    style={{
                      fontSize: 12,
                      fontFamily: "Rubik",
                      fontWeight: 500,
                      padding: 5,
                      height: 50,
                    }}
                  >
                    {fleet.fleet_name}
                  </option>
                ))}
            </select>
            <ValidationMessage
              message={"Fleet Is Required*"}
              required={validationError.fleet_name}
            />
          </div>
        </div>

        <div className="input-row" style={{ height: 80, marginTop: 10 }}>
          <div className="input-container" style={{ width: "100%" }}>
            <p className="input-title">Region *</p>
            <input
              type="string"
              className="input"
              id="region"
              value={formData.region}
              placeholder="Enter Region"
              maxLength={25}
              required
              onChange={onHandleChange}
            />
            <ValidationMessage
              message={"Region Is Required*"}
              required={validationError.region}
            />
          </div>
          <div className="input-container" style={{ width: "100%" }}>
            <p className="input-title">Version *</p>
            <input
              type="string"
              className="input"
              id="version"
              value={formData.version}
              placeholder="Enter Version"
              maxLength={5}
              required
              onChange={onHandleChange}
            />
            <ValidationMessage
              message={"Version is Required*"}
              required={validationError.version}
            />
          </div>
        </div>

        <div
          className="col-sm-12"
          style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            height: 60,
            marginTop: 20,
          }}
        >
          <button
            className="btn btn-dark btn-block"
            // disabled={loading}
            style={{ height: 38, fontSize: 14, fontFamily: "Rubik" }}
            onClick={onHandleSubmit}
          >
            {loading ? (
              <Lottie options={defaultOptions} height={25} width={25} />
            ) : action == "create" ? (
              "Submit"
            ) : (
              "Update"
            )}
          </button>
        </div>
        <ToastContainer />
      </FormContainer>
    </div>
  );
};

export default NewOnboard;
