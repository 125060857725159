const roleRules = {
  superAdminRole: [
    {
      id: 1,
      editable: true,
      visible: true,
      isNav: true,
    },
    {
      id: 14,
      editable: true,
      visible: true,
      isNav: true,
    },
    { id: 18, editable: true, visible: true, isNav: true },
  ],
  AdminRole: [
    {
      id: 1,
      editable: true,
      visible: true,
      isNav: true,
    },
    { id: 4, editable: true, visible: true, isNav: true },
    { id: 8, editable: true, visible: true, isNav: true },
    {
      id: 3,
      editable: true,
      visible: true,
      isNav: true,
    },
    { id: 12, editable: true, visible: true, isNav: true },
    { id: 14, editable: true, visible: true, isNav: true },
    { id: 18, editable: true, visible: true, isNav: false },
  ],
  AnalystRole: [
    {
      id: 1,
      editable: true,
      visible: true,
      isNav: true,
    },
    { id: 4, editable: true, visible: true, isNav: true },
    { id: 8, editable: true, visible: true, isNav: true },
    {
      id: 3,
      editable: true,
      visible: true,
      isNav: true,
    },
    { id: 12, editable: true, visible: true, isNav: true },
    { id: 14, editable: true, visible: true, isNav: false },
  ],
};
export { roleRules };
