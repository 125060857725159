import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  getLocalStorageData
} from "../../../utilities/helper";

import Paper from "@mui/material/Paper";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import DeleteModel from "../../../components/melecules/delete-model/delete-model";
import CommonTable from "../../../components/organizer/common-table/common-table";
import ErrorHandler from "../../../components/organizer/erro-handler/error-handler";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon,
} from "../../../constants/icons";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";

function TableList({ searchQuery, editData }) {
  const { statusCode, loading, data, apiRequest } = useAwsApiRequest();
  const tenant = getLocalStorageData("TenantID");

  const responseData = useRef();
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0); // Current page number
  const [totalPage, setTotalPage] = useState(0);
  const [filteredData, setFilteredData] = useState([]); // State variable to hold filtered data
  const [searchTerm, setSearchTerm] = useState("");
  const [tableHeader, setTableHeader] = useState([
    { id: 1, name: "Sl.No" },
    { id: 2, name: "Fleet ID" },
    { id: 3, name: "Fleet Name" },
    { id: 5, name: "Description" },
    { id: 6, name: "Created At" },
    { id: 7, name: "Action" },
  ]);

  const [deleteModel, setDeleteModel] = useState(false);

  const selectedData = useRef(null);

  useEffect(() => {
    getFleet();
  }, []);

  useLayoutEffect(() => {
    if (searchQuery !== undefined) {
      setSearchTerm(searchQuery);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (searchTerm.trim() !== "") {
      const filtered = tableData.filter((item) =>
        item.fleet_name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredData(filtered);
      setCurrentPage(0); // Reset to first page when search term changes
    } else {
      setFilteredData(tableData);
    }
  }, [searchTerm, tableData]);

  const getFleet = async () => {
    let data = {
      pathParam: {},
      endpoint: `http://localhost:8082/fleetservice/fleet`,
      method: "GET",
      body: {},
    };

    const response = await apiRequest(data);

    if (response?.records?.length) {
      responseData.current = response.records;
      const filteredData = filterFields(response.records, true);
      setTableData(filteredData);
      setFilteredData(filteredData);
      const dataCount = filteredData.length;
      const itemsPerPage = 10;
      const totalPages = Math.ceil(dataCount / itemsPerPage);
      setTotalPage(totalPages);
    }
  };

  const filterFields = (data, hide) => {
    return data.map((item, index) => {
      const { fleet_name, fleet_id, fleet_description, created_at } = item; // Extract necessary properties

      if (hide) {
        return {
          id: index + 1,
          fleet_id: fleet_id,
          fleet_name: fleet_name,
          fleet_description: fleet_description,
          created_at: moment(created_at).format("MMM Do YYYY, h:mm a"),
          action: "action",
        };
      } else {
        return {
          id: index + 1,
          fleet_id: fleet_id,
          fleet_name: fleet_name,
          fleet_description: fleet_description,
          created_at: moment(created_at).format("MMM Do YYYY, h:mm a"),
          action: "action",
        };
      }
    });
  };

  const action = (a, data) => {
    if (a == "edit") {
      editData(
        3,
        responseData.current.find((x) => x.fleet_name == data.fleet_name)
      );
    } else {
      setDeleteModel(true);
      selectedData.current = responseData.current.find(
        (x) => x.fleet_name == data.fleet_name
      );
    }
  };

  const showMoreData = (show) => {
    const filteredData = filterFields(responseData.current, show);
    setTableData(filteredData);
    if (show) {
      // If hide is true, remove the last 2 objects from the tableHeader array
      setTableHeader((prevHeader) =>
        prevHeader.slice(0, prevHeader.length - 1)
      );
    } else {
      // If hide is false or undefined, reset the tableHeader array to its original state
      setTableHeader([
        { id: 1, name: "Sl.No" },
        { id: 2, name: "Fleet ID" },
        { id: 3, name: "Fleet Name" },
        { id: 5, name: "Description" },
        { id: 6, name: "Created At" },
        { id: 7, name: "Action" },
      ]);
    }
  };

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected); // Update the current page
  };

  const close = () => {
    setDeleteModel(false);
  };

  const modelDelete = async () => {
    let data = {
      pathParam: {},
      endpoint: `http://localhost:8082/fleetservice/fleet/${selectedData.current?.fleet_id}`,
      method: "DELETE",
      body: {},
    };

    const response = await apiRequest(data);
    if (response?.status == 204) {
      toast.success("Successfully Deleted Fleet", {
        position: "top-right",
      });
      close();
      getFleet();
    } else {
      toast.error("Network Error", {
        position: "top-right",
      });
      close();
    }
  };

  const itemsPerPage = 10;
  const offset = currentPage * itemsPerPage; // Calculate offset for pagination
  const currentPageData = filteredData.slice(offset, offset + itemsPerPage);
  return (
    <ErrorHandler statusCode={statusCode} loading={loading}>
      <Paper sx={{ width: "100%", overflow: "hidden", marginTop: "2%" }}>
        <CommonTable
          tableHeader={tableHeader}
          data={currentPageData}
          onClick={action}
          showMoreData={showMoreData}
          showMoreIcon={false}
        />
        <br></br>

        <div
          style={{
            border: "1px solid #d3d3d3",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-evenly",
            padding: "5px",
            paddingTop: "15px",
          }}
        >
          <p
            style={{
              fontWeight: 600,
              color: "gray",
              fontSize: 13,
              fontFamily: "Rubik",
            }}
          >
            Showing {offset + 1} to {offset + currentPageData.length} of{" "}
            {filteredData.length} entries
          </p>
          <div style={{ marginTop: 2 }}>
            <ReactPaginate
              previousLabel={
                <ArrowBackIosNewOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              nextLabel={
                <ArrowForwardIosOutlinedIcon
                  style={{ fontSize: 14, fontWeight: 600 }}
                />
              }
              breakLabel={"...."}
              marginPagesDisplayed={2}
              pageCount={totalPage}
              onPageChange={handlePageClick}
              containerClassName={"paginationBttns"}
              previousLinkClassName={"previousBttns"}
              disabledClassName={"paginationDisabled"}
              activeClassName={"paginationActive"}
            />
          </div>
        </div>
      </Paper>
      {deleteModel && <DeleteModel deleteModel={modelDelete} close={close} />}
      <ToastContainer />
    </ErrorHandler>
  );
}

export default TableList;
