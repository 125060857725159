import { getTokens, getTokenCredentials, refreshToken } from "../services/aws";
import { getRefreshTime,getRoleType } from "../utilities/helper";
import { useSelector, useDispatch } from "react-redux";
import { setUserType, setTokens, setCred, getUsers } from "../redux/actions/users";
import { useState } from "react";


const useCredentials = () => {
  const [loading, setLoading] = useState(true);
  const [statusCode, setStatusCode] = useState(200);
  //redux
  const dispatch = useDispatch();

  //get all tokens from aws
  const setCredentials = async () => {
    const {
      accessToken,
      exp,
      idToken,
      refreshTokens,
      region,
      userRole,
      user,
      userrole,
      error,
    } = await getTokens();
    console.log("user",user);
    
    if (!error) {
      setStatusCode(200)
      setLoading(false);
      //update redux data
      dispatch(setUserType(userrole));
      dispatch(getUsers(user));
      dispatch(
        setTokens({
          accessToken,
          exp,
          idToken,
          refreshTokens,
          region,
          userRole,
        })
      );

      //calling token credential function
      setTokenCredentials();

      //checking token validity
      const refreshTime = getRefreshTime(exp, 5);
      setTimeout(() => {
        setRefreshToken();
        setTokenCredentials();
      }, refreshTime);
    }else{
      setStatusCode(500)
      setTimeout(() => {
        setLoading(false)
      }, 3000);
    }
  };

  //token credential functions
  const setTokenCredentials = async () => {
    //get all credentials from aws
    const { accessKeyId, secretAccessKey, sessionToken } =
      await getTokenCredentials();

    //store data to redux
    dispatch(setCred({ accessKeyId, secretAccessKey, sessionToken }));
  };

  //refresh token function
  const setRefreshToken = async () => {
    //refresh the token
    const { userrole, accessToken, idToken, region } = await refreshToken();

    //store data to redux
    dispatch(setUserType(userrole));
    dispatch(setTokens({ accessToken, idToken, region }));
  };

  return { setCredentials,loading,statusCode };
};

export default useCredentials;
