import { useState } from "react";
import useStatusStore from "../services/zustand/status-popup";
import axios from "axios";

const useAxiosApiRequest = () => {
  let tenantId = "c9c91d94-1685-4cb5-a7b7-f0e011b64866";
  console.log("tenantId from url", tenantId);
  if (!tenantId) { 
    tenantId = process.env.REACT_APP_USER_ROLE;
  }
  const setMessage = useStatusStore((state) => state?.setMessage);
  
  const [statusCode, setStatusCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null); 
  const [error, setError] = useState(null);

  // Helper function to build the URL with query parameters
  const buildUrlWithParams = (endpoint, queryParams) => {
    if (!queryParams || Object.keys(queryParams).length === 0) {
      return endpoint; // No queryParams, return the endpoint directly
    }
    
    const url = new URL(endpoint);
    
    // Append query params to the URL
    Object.keys(queryParams).forEach(key => {
      if (queryParams[key] !== undefined && queryParams[key] !== null) {
        url.searchParams.append(key, queryParams[key]);
      }
    });
    console.log("url : -----" ,url)
    return url.toString(); // Return full URL with query parameters
  };

  const apiRequest = async (
    { pathParam, endpoint, method, body, defaultTen, headerCred, queryParams },
    toast
  ) => {
    console.log("pathParam", pathParam);
    console.log("endpoint", endpoint);
    console.log("method", method);
    console.log("body", body);
    console.log("defaultTen", defaultTen);
    console.log("headerCred", headerCred);

    setError(null);
    setStatusCode(null);
    setLoading(true);

    if (!tenantId && !defaultTen) {
      setMessage(null, "Tenant Id Not Set", "error");
      return;
    }

    const additionalParams = {
      headers: headerCred
        ? headerCred
        : { "tenant-id": defaultTen || tenantId },
    };

    // Check if queryParams exist and decide the URL
    const fullUrl = queryParams && Object.keys(queryParams).length > 0 
      ? buildUrlWithParams(endpoint, queryParams)
      : endpoint; // Use endpoint directly if no queryParams

    console.log("Final URL:", fullUrl);

    try {
      const response = await axios({
        method: method,
        url: fullUrl,
        data: body,
        headers: additionalParams?.headers,
      });

      console.log("response", response);
      console.log("status", response?.status);
      console.log("data", response?.data);

      setData(response?.data);
      setLoading(false);
      setStatusCode(response?.data?.status);

      if (method === "POST" || toast) {
        setMessage(
          response?.data?.status,
          response?.data?.message ? response?.data?.message : "",
          response?.data?.status === 200 ? "success" : "error"
        );
      }

      return response?.data;
    } catch (err) {
      console.log("err", err);
      setLoading(false);
      const responseError = err?.response;
      if (responseError) {
        setError(responseError?.data.message || "An error occurred");
        setStatusCode(responseError?.data?.status);
        setMessage(
          responseError?.data?.status,
          responseError?.data.message || "An error occurred",
          "error"
        );
      } else {
        setStatusCode(500);
        setError("Network error");
        setMessage(500, "Network error", "error");
      }
    }

    setTimeout(() => {
      setError(null);
    }, 500);
  };

  return { statusCode, loading, data, error, apiRequest };
};

export default useAxiosApiRequest;
