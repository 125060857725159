import { getLocalStorageData } from "../utilities/helper";

const DELETE = "DELETE";
const GET = "GET";
const additionalParams = {
  headers: {
    "tenant-id": getLocalStorageData("TenantID"),
  },
};
const pathParams = {
  text: "hi",
};
const body = {};

const { innerWidth: width, innerHeight: height } = window;

const statusCodeMessage = {
  0: "Creating",
  200: "Success",
  201: "Success",
  400: "Bad Request",
  401: "Unauthorized",
  402: "Payment Required",
  403: "Forbidden",
  404: "Not Found",
  406: "Not Acceptable",
  408: "Request Timeout",
  429: "Too Many Request",
  500: "Internal Server Error OR Network Error",
};

export {
  additionalParams, body, DELETE,
  GET, height, pathParams, statusCodeMessage, width
};

