import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import indicator from "../../assets/lottie/loader.json";
import FormContainer from "../../components/organizer/form-container/form-container";
import useAwsApiRequest from "../../hook/useAwsApiRequest";
import RadioInput from "./radio-input/radio-input";

const AddUser = ({ selectedData, action, goBack }) => {
  const width = useSelector((state) => state.width.width);
  const userType = useSelector((state) => state.userType.userType);
  const userRole = process.env.REACT_APP_USER_ROLE;
  const tenantId = JSON.parse(localStorage.getItem("TenantID"));
  const cred = useSelector((state) => state.cred.cred);
  // const userRoleSuperuser = process.env.REACT_APP_TENANTONBOARDING_USER_ROLE;

  const [formData, setFormData] = useState({
    userName: selectedData ? selectedData.userName : "",
    emailId: selectedData ? selectedData.emailId : "",
    temporaryPassword: selectedData ? selectedData.temporaryPassword : "",
    userPoolId: selectedData ? selectedData.userPoolId : "",
    user_role: "Admin",
    tenant_name: "",
  });
  const [tenantData, setTenantData] = useState([]);
  const [usernameError, setUsernameError] = useState('');
  const [selectedTenantID, setSelectedTenantID] = useState("");
  const [userPoolId, setUserPoolId] = useState([]);
  const [passwordPolicy, setPasswordPolicy] = useState([]);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [isUppercaseValid, setIsUppercaseValid] = useState(false);
  const [isNumbersValid, setIsNumbersValid] = useState(false);
  const [isLowercaseValid, setIsLowercaseValid] = useState(false);
  const [isSpecialCharValid, setIsSpecialCharValid] = useState(false);
  const [validationError, setValidationError] = useState({
    userName: false,
    userNameLength:false,
    emailId: false,
    temporaryPassword: false,
    user_role: false,
  });

  const { statusCode, loading, data, error, apiRequest } = useAwsApiRequest();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: indicator,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const userData = {
    userName: `${formData?.userName}`,
    emailId: `${formData?.emailId}`,
    temporaryPassword: `${formData?.temporaryPassword}`,
    userPoolId: userPoolId,
  };

  const addUserGroup = {
    userPoolId: `${userPoolId}`,
    username: `${formData?.userName}`,
    groupName: `${formData?.user_role}`,
  };

  useEffect(() => {
    if (error) {
      const msg = error?.response?.data?.msg;
      if (msg) {
        toast.warn(msg, { position: "top-right" });
      } else {
        toast.error("No Network Connection", { position: "top-right" });
      }
    }
  }, [error]);

  useEffect(() => {
    getUserPoolConfig();
    if (userType === "superAdminRole") {
      getTenantList();
    }
  }, []);

  useEffect(() => {
    if (userType === "superAdminRole" && selectedTenantID) {
      getTenantDataMongo();
      getPasswordPolicy();
    }
  }, [selectedTenantID, userType]);

  useEffect(() => {
    if (userType === "AdminRole" && selectedTenantID) {
      getPasswordPolicy();
    }
  }, [selectedTenantID, userType]);

  const handleTenantName = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setValidationError({ ...validationError, [id]: false });
    const selectedModelName = e.target.value;
    const selectedModel = tenantData.find(
      (model) => model.tenantName === selectedModelName
    );
    if (selectedModel) {
      setSelectedTenantID(selectedModel.tenantID);
    }
  };

  const getTenantList = async () => {
    const data = {
      pathParam: {},
      endpoint: `http://localhost:8007/tenantservice/tenantrequest`,
      method: "GET",
      body: {},
      defaultTen: userRole,
    };

    const res = await apiRequest(data);

    if (res) {
      const data2 = {
        pathParam: {},
        endpoint: `http://localhost:8007/tenantservice/tenant`,
        method: "GET",
        body: {},
        defaultTen: userRole,
      };
      const res2 = await apiRequest(data2);

      if (res2) {
        const combinedData = res.map((request) => ({
          status: request.status,
          tenantName: res2.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId
          )?.tenantName,
          tenantID: res2.find(
            (tenant) => tenant.tenantStagingId === request.tenantStagingId
          )?.tenantId,
        }));
        const filteredData = combinedData.filter((item) => item.status === "S");
        setTenantData(filteredData);
      }
    }
  };

  const getUserPoolConfig = () => {
    try {
      const storedTenantId = localStorage.getItem("TenantID");
      const tenantId = storedTenantId ? JSON.parse(storedTenantId) : null;
      setSelectedTenantID(tenantId);
      const storedAmplifyConfig = localStorage.getItem("amplifyConfig");
      const amplifyConfig = storedAmplifyConfig
        ? JSON.parse(storedAmplifyConfig)
        : null;
      const userPoolIDConfig = amplifyConfig?.aws_user_pools_id;
      if (userPoolIDConfig) {
        setUserPoolId(userPoolIDConfig);
      }
    } catch (error) {
      console.log("Error retrieving userID from local storage");
    }
  };

  const getTenantDataMongo = async () => {
    const data = {
      pathParam: {},
      endpoint: `http://localhost:8084/mongoservice/`,
      method: "GET",
      body: {},
      defaultTen: selectedTenantID,
    };

    const response = await apiRequest(data);
    console.log(response);
    if (response) {
      const userpoolid = response[0]?.userPoolId;
      console.log(userpoolid);
      setUserPoolId(userpoolid);
    }
  };

  const getPasswordPolicy = async () => {
    const data = {
      pathParam: {},
      endpoint: `http://localhost:8007/tenantservice/tenant`,
      method: "GET",
      body: {},
      defaultTen: selectedTenantID,
    };

    const response = await apiRequest(data);

    if (response) {
      const data = response[0];
      setPasswordPolicy(data);
    }
  };

  const onHandleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    setValidationError({ ...validationError, [id]: false });

    if (id === "temporaryPassword") {
      const password = value;
      setIsLengthValid(password.length >= passwordPolicy.tenantPasswordLength);
      setIsUppercaseValid(password.match(/[A-Z]/) !== null);
      setIsLowercaseValid(password.match(/[a-z]/) !== null);
      setIsNumbersValid(password.match(/[0-9]/) !== null);
      setIsSpecialCharValid(password.match(/[!@#$%^&*]/) !== null);
    }
  };

  function validateEmail(email) {
    var regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  const validateForm = () => {
    let isValid = true;

    if (!formData.userName) {
      setValidationError((prevState) => ({ ...prevState, userName: true }));
      isValid = false;
    } else if(formData.userName.length <1 || formData.userName.length >12) {
      setValidationError({ ...validationError, userNameLength: true });
      isValid = false;
    }
    else {
      setValidationError({ ...validationError, userNameLength: false });
      setValidationError((prevState) => ({ ...prevState, userName: false }));
    }

    if (!formData.emailId || !validateEmail(formData.emailId)) {
      setValidationError((prevState) => ({ ...prevState, emailId: true }));
      isValid = false;
    }
    else {
      setValidationError((prevState) => ({ ...prevState, emailId: false }));
    }

    if (!formData.temporaryPassword) {
      setValidationError((prevState) => ({
        ...prevState,
        temporaryPassword: true,
      }));
      isValid = false;
    } else {
      setValidationError((prevState) => ({
        ...prevState,
        temporaryPassword: false,
      }));
    }

    return isValid;
  };

  const onHandleSubmit = async () => {
    if (validateForm()) {
      if (action !== "update") {
        const data = {
          pathParam: {},
          endpoint: `http://localhost:8005/cognitoservice/userpool/user`,
          method: "POST",
          headerCred: {
            "tenant-id": tenantId || process.env.REACT_APP_USER_ROLE,
            "cli-region": process.env.REACT_APP_DEFAULT_REGION,
            "aws-secret": cred?.secretAccessKey,
            "aws-key": cred?.accessKeyId,
            "aws-session": cred?.sessionToken,
          },
          body: userData,
        };
        const response = await apiRequest(data);
        console.log(response);
        if (response) {
          const data = {
            pathParam: {},
            endpoint: `http://localhost:8005/cognitoservice/usertogroup`,
            method: "PATCH",
            headerCred: {
              "tenant-id": tenantId || process.env.REACT_APP_USER_ROLE,
              "cli-region": process.env.REACT_APP_DEFAULT_REGION,
              "aws-secret": cred?.secretAccessKey,
              "aws-key": cred?.accessKeyId,
              "aws-session": cred?.sessionToken,
            },
            body: addUserGroup,
          };

          const response1 = await apiRequest(data);
          if (response1?.status === 200) {
            setFormData({
              userName: "",
              emailId: "",
              temporaryPassword: "",
            });
            toast.success("Successfully Updated User", {
              position: "top-right",
            });
            setTimeout(() => {
              goBack(1);
            }, 2000);
          }
        }
      } else {
        // Update logic here
      }
    }
  };

  const isPasswordValidForUser = (password) => {
    const {
      tenantPasswordLength,
      tenantPasswordRequireUppercase,
      tenantPasswordRequireLowercase,
      tenantPasswordRequireNumber,
      tenantPasswordRequireSpecial,
    } = passwordPolicy;

    const isLengthValid = password.length >= tenantPasswordLength;
    const isUppercaseValid = tenantPasswordRequireUppercase
      ? password.match(/[A-Z]/) !== null
      : true;
    const isLowercaseValid = tenantPasswordRequireLowercase
      ? password.match(/[a-z]/) !== null
      : true;
    const isNumbersValid = tenantPasswordRequireNumber
      ? password.match(/[0-9]/) !== null
      : true;
    const isSpecialCharValid = tenantPasswordRequireSpecial
      ? password.match(/[!@#$%^&*]/) !== null
      : true;

    return (
      isLengthValid &&
      isUppercaseValid &&
      isLowercaseValid &&
      isNumbersValid &&
      isSpecialCharValid
    );
  };

  const isPasswordValid = isPasswordValidForUser(formData?.temporaryPassword);

  const ValidationMessage = ({ message, required }) => {
    return (
      <div style={{ height: 3 }}>
        {required && <p className="error-message">{message}</p>}
      </div>
    );
  };

  return (
    <div
      style={{
        width: window.innerWidth - width - 30,
        display: "flex",
        justifyContent: "center",
      }}
    >
      <FormContainer
        header={true}
        minusWidth={0}
        title={action === "create" ? "Create User" : "Update User"}
      >
        <div className="input-row" style={{ height: 80, marginTop: 10 }}>
          <div className="input-container" style={{ width: "100%" }}>
            <p className="input-title">Username *</p>
            <input
              type="text"
              className="input"
              id="userName"
              value={formData.userName}
              placeholder="Enter username"
              maxLength={128}
              required
              onChange={onHandleChange}
            />
            <ValidationMessage
            message={"Username must be between 1 and 128 characters long"}
            required={validationError.userNameLength}
          />
            <ValidationMessage
              message={"Username is required"}
              required={validationError.userName}
            />
          </div>
          <div className="input-container" style={{ width: "100%" }}>
            <p className="input-title">Email Id *</p>
            <input
              type="email"
              className="input"
              id="emailId"
              value={formData.emailId}
              placeholder="Enter email id"
              minLength={6}
              maxLength={129}
              required
              onChange={onHandleChange}
            />
            <ValidationMessage
              message={"Enter valid email id"}
              required={validationError.emailId}
            />
          </div>
        </div>
        {userType === "superAdminRole" && (
          <div className="input-container" style={{ width: "100%" }}>
            <p className="input-title">Select Tenant *</p>
            <select
              className="input"
              id="tenant_name"
              value={formData?.tenant_name}
              required
              onChange={handleTenantName}
            >
              <option>Select Tenant Name</option>
              {tenantData.map((getTenantName) => (
                <option key={getTenantName.tenantID} value={getTenantName.tenantName}>
                  {getTenantName.tenantName}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="input-container" style={{ width: "100%" }}>
          <p className="input-title">Temporary Password *</p>
          <input
            className="input"
            placeholder="Enter Temporary Password"
            id="temporaryPassword"
            value={formData.temporaryPassword}
            type="string"
            maxLength={255}
            required
            onChange={onHandleChange}
          />
          <ValidationMessage
            message={"Temporary Password is required"}
            required={!isPasswordValid}
            // required={validationError.temporaryPassword}
          /><br/>
          <ul>
            {passwordPolicy?.tenantPasswordRequireUppercase && (
              <li
                style={{
                  color: isUppercaseValid ? "green" : "red",
                  fontSize: "12px",
                  fontFamily: "Rubik",
                }}
              >
                Password should contain uppercase letters
              </li>
            )}
            {passwordPolicy?.tenantPasswordRequireLowercase && (
              <li
                style={{
                  color: isLowercaseValid ? "green" : "red",
                  fontSize: "12px",
                  fontFamily: "Rubik",
                }}
              >
                Password should contain lowercase letters
              </li>
            )}
            {passwordPolicy?.tenantPasswordRequireNumber && (
              <li
                style={{
                  color: isNumbersValid ? "green" : "red",
                  fontSize: "12px",
                  fontFamily: "Rubik",
                }}
              >
                Password should contain numbers
              </li>
            )}
            {passwordPolicy?.tenantPasswordRequireSpecial && (
              <li
                style={{
                  color: isSpecialCharValid ? "green" : "red",
                  fontSize: "12px",
                  fontFamily: "Rubik",
                }}
              >
                Password should contain special characters
              </li>
            )}
            {passwordPolicy?.tenantPasswordLength && (
              <li
                style={{
                  color: isLengthValid ? "green" : "red",
                  fontSize: "12px",
                  fontFamily: "Rubik",
                }}
              >
                Minimum length: {passwordPolicy.tenantPasswordLength}
              </li>
            )}
          </ul>
          <p className="input-title" style={{ marginTop: 10 }}>
            Select User Role *
          </p>
          <RadioInput
            name={"role"}
            label={"Admin"}
            onChange={() => onHandleChange({ target: { id: "user_role", value: "Admin" } })}
          />
          <RadioInput
            name={"role"}
            label={"Analyst"}
            onChange={() => onHandleChange({ target: { id: "user_role", value: "Analyst" } })}
          />
          <ValidationMessage
            message={"User Role is required"}
            required={validationError.user_role}
          />
        </div>

        <div
          className="col-sm-12"
          style={{
            width: "100%",
            justifyContent: "center",
            display: "flex",
            height: 60,
          }}
        >
          <button
            className="btn btn-dark btn-block"
            style={{ height: 38, fontSize: 14, fontFamily: "Rubik" }}
            onClick={onHandleSubmit}
          >
            {loading ? (
              <Lottie options={defaultOptions} height={25} width={25} />
            ) : action === "create" ? (
              "Submit"
            ) : (
              "Update"
            )}
          </button>
        </div>
        <ToastContainer />
      </FormContainer>
    </div>
  );
};

export default AddUser;
