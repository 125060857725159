import AutorenewIcon from "@mui/icons-material/Autorenew";
import Close from "@mui/icons-material/Close";
import { animated, useSpring } from "@react-spring/web";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import "react-step-progress-bar/styles.css";
import ProgressBar from "../../../components/melecules/progress-bar/progress-bar";
import {
  ArrowBackIosNewOutlinedIcon,
  ArrowForwardIosOutlinedIcon
} from "../../../constants/icons";
import useAwsApiRequest from "../../../hook/useAwsApiRequest";
import { ClearPercentage } from "../../../utilities/clearPercentage";
import "./campaignStatus.css";
const CampaignStatus = ({ openRecordList, onClose, campaignId }) => {
  const { statusCode, loading, data, error, apiRequest } = useAwsApiRequest();
  const slideIn = useSpring({
    transform: openRecordList ? "translateY(0%)" : "translateY(100%)",
    opacity: openRecordList ? 1 : 0,
  });

  const width = useSelector((state) => state.width.width);
  const boxWidth = (window.innerWidth - width) / 4;

  const [filterType, setFilterType] = useState("All");
  const [isRotating, setIsRotating] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [campaignStatus, setCampaignStatus] = useState([]);
  const [summary, setSummary] = useState("");
  const limit = '10';

  useEffect(() => {
    getCampaignSummary();
    const intervalId = setInterval(getCampaignSummary, 3000);
    // Cleanup function to stop polling when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getCampaignStatus('1','all');
  }, []);

  const getCampaignSummary = async () => {
    let data = {
      pathParam: {},
      endpoint: `http://localhost:8091/campaignstatus/campaignsummary/${campaignId?.campaignId}`,
      method: "GET",
      body: {},
    };

    const response = await apiRequest(data);
    if (response) {
      setSummary(response?.campaignSummary);
    }
  };

  const getCampaignStatus = async (page,filter) => {
    const queryParams = {
      status: filter,
      page: page,
      limit: limit,
    };

    let data = {
      pathParam: {},
      endpoint: `http://localhost:8091/campaignstatus/campaign/${campaignId?.campaignId}`,
      // endpoint: `http://192.168.56.1:8091/campaignstatus/campaign/cid-2987?status=SUCCEEDED&page=${page}&limit=20`,
      method: "GET",
      body: {},
      queryParams: queryParams,
    };

    const response = await apiRequest(data);
    if (response) {
      const data = response?.vinStatus;
      setCampaignStatus(data);
      setPageCount(response?.pagination?.totalPages);
    }
  };

  const handlePageClick = (page) => {
    setPage(page.selected);
    getCampaignStatus(page.selected + 1,filterType);
  };

  const setFilterTypeStatus = (filter) => {
    setFilterType(filter);
    getCampaignStatus('1',filter);
  }

  const getLatestData = () => {
    getCampaignStatus('1','all');
  }

  return (
    <animated.div className="bottom-sheet" style={slideIn}>
      <div className="sheet-header">
        <div
          style={{
            display: "flex",
            width: 300,
            flexDirection: "column",
            backgroundColor: "white",
            padding: 5,
            borderRadius: 5,
          }}
        >
          {/* <div style={{ fontSize: 12, fontFamily: "Rubik" }}>
            Campaign Name:{" "}
          </div> */}
          <div style={{ fontSize: 12, fontFamily: "Rubik" }}>
            Campaign ID: {campaignId?.campaignId}{" "}
          </div>
          <div style={{ fontSize: 12, fontFamily: "Rubik" }}>
            Date: {campaignId?.date}{" "}
          </div>
        </div>
        <div className="description-details">
          <h2
            className="sheet-title"
            style={{
              width: 80,
              border: "1px solid #19172C",
              backgroundColor: filterType == "all" ? "#19172C" : "#fff",
              color: filterType == "all" ? "#fff" : "#000",
              cursor: "pointer",
            }}
            onClick={() => setFilterTypeStatus("all")}
          >
            All
          </h2>
        </div>
        <div className="description-details">
          <h2
            className="sheet-title"
            style={{
              backgroundColor: filterType == "SUCCEEDED" ? "#d4f8d4" : "#fff",
              cursor: "pointer",
              width: 130,
              border: "1px solid #d4f8d4",
            }}
            onClick={() => setFilterTypeStatus("SUCCEEDED")}
          >
            Success: {summary?.SUCCEEDED}
          </h2>
        </div>
        {/* <div className="description-details">
          <h2
            className="sheet-title"
            style={{
              backgroundColor: filterType == "IN_PROGRESS" ? "#add8e6" : "#fff",
              cursor: "pointer",
              width: 130,
              border: "1px solid #add8e6",
            }}
            onClick={() => setFilterTypeStatus("IN_PROGRESS")}
          >
            In Progress: {summary?.IN_PROGRESS}
          </h2>
        </div> */}
        <div className="description-details">
          <h2
            className="sheet-title"
            style={{
              backgroundColor: filterType == "FAILED" ? "#FCC981" : "#fff",
              cursor: "pointer",
              width: 130,
              border: "1px solid #FCC981",
            }}
            onClick={() => setFilterTypeStatus("FAILED")}
          >
            Failed: {summary?.FAILED}
          </h2>
        </div>
        <div className="description-details">
          <h2
            className="sheet-title"
            style={{
              backgroundColor: filterType == "CANCELED" ? "#ffcccb" : "#fff",
              cursor: "pointer",
              width: 130,
              border: "1px solid #ffcccb",
            }}
            onClick={() => setFilterTypeStatus("CANCELED")}
          >
            Cancelled: {summary?.CANCELED}
          </h2>
        </div>
        <div
          style={{
            backgroundColor: "#19172C",
            height: 30,
            width: 30,
            borderRadius: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            cursor: "pointer",
          }}
          onClick={getLatestData}
        >
          <AutorenewIcon
            className={`renew-icon ${isRotating ? "rotate" : ""}`}
            style={{ fontSize: 26, color: "white" }}
          />
        </div>
        <div
          style={{
            backgroundColor: "#19172C",
            height: 30,
            width: 30,
            borderRadius: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "white",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          <Close />
        </div>
      </div>
      <div className="sheet-content">
        <div
          className="single-container"
          style={{ backgroundColor: "#A6B8DC" }}
        >
          <div
            style={{
              width: 100,
              fontSize: 14,
              fontFamily: "Rubik",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: 50,
            }}
          >
            Sl.No
          </div>
          <div
            style={{
              width: boxWidth,
              fontSize: 14,
              fontFamily: "Rubik",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Vin
          </div>
          <div
            style={{
              width: boxWidth + 150,
              fontSize: 14,
              fontFamily: "Rubik",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Status
          </div>
          <div
            style={{
              width: boxWidth / 1.5,
              fontSize: 14,
              fontFamily: "Rubik",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Percentage Completed
          </div>
          <div
            style={{
              width: boxWidth / 2.5,
              fontSize: 14,
              fontFamily: "Rubik",
              borderRight: "1px solid #fff",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Date
          </div>
        </div>

        {campaignStatus && campaignStatus.length ? (
          campaignStatus.map((item, index) => (
            <div
              className="single-container"
              style={{
                height: 75,
                backgroundColor: index % 2 == 0 ? "#f2f2f2" : "#fff",
              }}
              key={item?.VIN}
            >
              <div
                style={{
                  width: 100,
                  fontSize: 12,
                  fontFamily: "Rubik",
                  borderRight: "1px solid #f2f2f2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {index + 1}
              </div>
              <div
                style={{
                  width: boxWidth,
                  fontSize: 12,
                  fontFamily: "Rubik",
                  borderRight: "1px solid #f2f2f2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {item?.VIN}
              </div>
              <div
                style={{
                  width: boxWidth + 150,
                  fontSize: 12,
                  fontFamily: "Rubik",
                  borderRight: "1px solid #f2f2f2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <ProgressBar currentStatus={item?.statusDetails?.operation} />
              </div>
              <div
                style={{
                  width: boxWidth / 1.5,
                  fontSize: 12,
                  fontFamily: "Rubik",
                  borderRight: "1px solid #f2f2f2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div style={{ width: 40, height: 40 }}>
                  <CircularProgressbar
                    // value={percentage}
                    // text={`${percentage}%`}
                    value={ClearPercentage(item?.statusDetails?.progress)}
                    text={`${item?.statusDetails?.progress}`}
                    styles={buildStyles({
                      // Rotation of path and trail, in number of turns (0-1)
                      rotation: 0.25,

                      // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                      strokeLinecap: "butt",

                      // Text size
                      textSize: "30px",

                      // How long animation takes to go from one percentage to another, in seconds
                      pathTransitionDuration: 0.5,

                      // Can specify path transition in more detail, or remove it entirely
                      // pathTransition: 'none',

                      // Colors
                      pathColor: `#19172C`,
                      textColor: "#000",
                      trailColor: "#d6d6d6",
                      backgroundColor: "#3e98c7",
                    })}
                  />
                </div>
              </div>
              <div
                style={{
                  width: boxWidth / 2.5,
                  fontSize: 12,
                  fontFamily: "Rubik",
                  borderRight: "1px solid #f2f2f2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {moment(item?.timestamp).format("MMM Do YYYY, h:mm a")}
              </div>
            </div>
          ))
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
              height: "80%",
              fontSize: 12,
              fontFamily: "Rubik",
            }}
          >
            No Data Found
          </div>
        )}
      </div>

      <div className="pagination-box">
        <ReactPaginate
          previousLabel={
            <ArrowBackIosNewOutlinedIcon
              style={{ fontSize: 14, fontWeight: 600 }}
            />
          }
          nextLabel={
            <ArrowForwardIosOutlinedIcon
              style={{ fontSize: 14, fontWeight: 600 }}
            />
          }
          breakLabel={"...."}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"paginationBttns"}
          previousLinkClassName={"previousBttns"}
          disabledClassName={"paginationDisabled"}
          activeClassName={"paginationActive"}
        />
      </div>
    </animated.div>
  );
};

export default CampaignStatus;
